<!-- 
	@component
	
	Logo component

	@prop wrapperProps - Props to pass to the wrapper component
	@prop svgProps - Props to pass to the svg component
-->
<script context="module" lang="ts">
	export type LogoWrapperProps = {
		classes?: string;
		height: string;
		width: string;
	};

	export type LogoSvgProps = {
		height: number;
		width: number;
	};
</script>

<script lang="ts">
	export let wrapperProps: LogoWrapperProps = {
		classes: '',
		height: 'h-8',
		width: 'w-10'
	};

	export let svgProps: LogoSvgProps = {
		height: 24,
		width: 40
	};
</script>

<div
	class="inline-flex items-center justify-center {wrapperProps.height} {wrapperProps.width} {wrapperProps.classes}"
>
	<svg
		fill="currentColor"
		height={svgProps.height}
		viewBox="0 0 422 184"
		width={svgProps.width}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M218.164 139.249L351.719 62.1413L368.854 91.8202L235.299 168.928L218.164 139.249Z"
			fill="currentColor"
		/>
		<path
			d="M66.3738 132.902L188.935 62.1413L206.07 91.8202L83.509 162.581L66.3738 132.902Z"
			fill="currentColor"
		/>
		<path
			d="M216.917 179.51L159.047 79.2765L188.726 62.1414L246.596 162.375L216.917 179.51Z"
			fill="currentColor"
		/>
		<path
			d="M0.547363 170.949L67.3249 132.395L96.5043 183.371L0.547363 170.949Z"
			fill="currentColor"
		/>
		<path
			d="M52.9529 92.411L186.508 15.3029L203.643 44.9818L70.088 122.09L52.9529 92.411Z"
			fill="currentColor"
		/>
		<path
			d="M216.593 91.5559L338.28 21.3002L355.415 50.9791L233.729 121.235L216.593 91.5559Z"
			fill="currentColor"
		/>
		<path
			d="M232.115 122.152L174.444 22.263L204.123 5.12784L261.794 105.017L232.115 122.152Z"
			fill="currentColor"
		/>
		<path
			d="M421.453 13.0516L355.048 51.184L325.496 0.628906L421.453 13.0516Z"
			fill="currentColor"
		/>
	</svg>
</div>
