<script context="module" lang="ts">
	const MIN_TIME_TO_DISPLAY = 300; // 0.30 seconds
</script>

<script lang="ts">
	import { afterNavigate, beforeNavigate } from '$app/navigation';

	export let classes = '';

	let isLoading = false;
	let timeoutId: ReturnType<typeof setTimeout>;

	const showIndicator = () => {
		isLoading = true;
		clearTimeout(timeoutId);
	};

	const hideIndicator = () => {
		timeoutId = setTimeout(() => {
			isLoading = false;
		}, MIN_TIME_TO_DISPLAY);
	};

	beforeNavigate(showIndicator);
	afterNavigate(hideIndicator);
</script>

<div
	data-is-loading={isLoading}
	data-testid="navigation-indicator"
	class=" h-[2px] overflow-hidden {classes}"
>
	{#if isLoading}
		<div class="animate-loading h-full w-full rounded-full bg-primary-500" />
	{/if}
</div>

<style>
	.animate-loading {
		animation: loading 2s linear infinite;
	}

	@keyframes loading {
		0% {
			transform: translateX(-100%);
		}
		100% {
			transform: translateX(100%);
		}
	}
</style>
